import React from 'react'

const Spin = () => {
  return (
    <div id="spin">
        <div id="spinround">
        </div>
    </div>
  )
}

export default Spin